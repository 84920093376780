#root{
  height: 100%;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
  
}
main {
  height: 100%;
  display: grid;
  grid-template-rows: 100px auto 100px;
}
section {
  height: 100%;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header, footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  background-color: rgb(66, 128, 179);
  z-index: 4;
}

.modal-popup-background{
  width: 100%;
  height: 100%;
  background: #020202;
  position: absolute;
  z-index: -1;
  opacity: 0.4;
}

.modal-popup-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0 10px;
  background: #236092;
  color: white;
  height: 32px;
}

.modal-popup-content{
  background: white;
  padding: 10px;
  height: 100%;
}

.modal-popup-close{
  cursor: pointer;
}

.modal-popup-container {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
}